import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';
import { ResponseBean, ResponseItemBean, SMSBean } from '../model/model';
import { Observable } from 'rxjs';
import { UserDto } from '../model/dto/userDto';
import { EntityNameDto } from '../model/dto/entityNameDto';
import { CreateUserRequestDto } from '../model/dto/createUserRequestDto';
import { LoginResponseDto } from '../model/dto/loginResponseDto';
import { ActivateUserDto } from '../model/dto/activateUserDto';
import { LoginRequestDto } from '../model/dto/loginRequestDto';

@Injectable()
export class UserService extends BaseRequestService {

    getUsers(): Observable<ResponseItemBean<UserDto[]>> {
        return this.get2('user');
    }

    me(): Observable<ResponseItemBean<UserDto>> {
        return this.get2('user/me');
    }

    getUser(id: number): Observable<ResponseItemBean<UserDto>> {
        return this.get2('user/' + id);
    }

    updateUser(id: number, user: UserDto): Observable<ResponseBean> {
        return this.put2('user/' + id, user);
    }

    addUser(user: CreateUserRequestDto): Observable<ResponseItemBean<UserDto>> {
        return this.post2('user/', user);
    }

    getUserRoles(): Observable<ResponseItemBean<EntityNameDto[]>> {
        return this.get2('user/role');
    }

    resendInvitation(id: number) {
        return this.get2(`user/${id}/resend_invitation`);
    }

    resetPassword(email: string): Observable<ResponseBean> {
        return this.get2(`user/reset_password?email=${email}`);
    }

    activate(user: ActivateUserDto): Observable<ResponseItemBean<LoginResponseDto>> {
        return this.post2('user/activate', user);
    }

    login(user: LoginRequestDto): Observable<ResponseItemBean<LoginResponseDto>> {
        return this.post2('auth/login', user);
    }

    loginWithToken(token: string): Observable<ResponseItemBean<LoginResponseDto>> {
        return this.post2('auth/login/token', token);
    }

    getOutgoingMessages(userId: number, startTime: number, endTime: number): Observable<ResponseItemBean<SMSBean[]>> {
        const p = {};
        p['start_date'] = startTime;
        p['end_date'] = endTime;
        if (userId) {
            p['user_id'] = userId;
        }
        return this.get('sms/outgoing', p);
    }

    getIncomingMessages(userId: number, startTime: number, endTime: number): Observable<ResponseItemBean<SMSBean[]>> {
        const p = {};
        p['start_date'] = startTime;
        p['end_date'] = endTime;
        if (userId) {
            p['user_id'] = userId;
        }
        return this.get('sms/incoming', p);
    }
}
